<template>
  <div class="rightLink">
    <div class="bottomDiv">
      <div class="title">下发操作结果</div>
      <div class="formDiv">
        <el-form label-position="right" label-width="80px">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="区域:">
                <div class="m-4">
                  <el-cascader v-model="value" :options="options" @change="handleChange" />
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="站点名称:">
                <el-input
                  v-model="FormData.serial_number"
                  placeholder="请输入站点名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="站点id:">
                <el-input
                  v-model="FormData.dutuies"
                  placeholder="请输入站点id"
                ></el-input>
              </el-form-item>
            </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-radio-group v-model="size" label="size control" size="small">
              </el-radio-group>
              <el-col :span="2"></el-col>
                <el-col :span="12" style="margin-left:-5px">
                <div class="demo-date-picker">
                  <div class="block">
                    <el-date-picker
                      v-model="value1"
                      type="daterange"
                      range-separator="To1"
                      start-placeholder="Start date"
                      end-placeholder="End date"
                      :size="size"
                    />
                  </div>
                </div>
                </el-col>
                <el-col :span="1">
                  <div class="BtnSearch" @click="searchBtn()">查询</div>
                </el-col>
                
              </el-row>
           
          
            </el-form>
          </div>
      <!-- 表格部分 -->
      <div class="tableDiv">
      <el-table
        ref="multipleTableRef"
        :data="tableData"
        border
        v-loading="loading"
        style="
          width: 90%;
          margin: 20px auto;
          height: 379px;
          overflow-y: auto;
        "
      >
          <el-table-column label="设备id" prop="serial_number" />
          <el-table-column label="设备名称" prop="user_name" width="180" />
          <el-table-column label="设备编号" prop="user_name" width="200" />
          <el-table-column label="操作类型" prop="contact_way" width="200" />
          <el-table-column label="操作结果" prop="wx_id" />
          <el-table-column label="操作时间" prop="mailbox" />
          
        </el-table>
        <div class="pagination">
        <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :small="small"
            layout="total, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
      </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { onMounted } from "vue";
import { ref } from 'vue'
import { onMounted, reactive, toRefs } from "vue-demi";
// import { district_sel , district_upd } from "@/request/api";
// import { ElMessage } from "element-plus";
export default {
  name: "EditArea",
  setup() {
    const data = reactive({
      //表单数据
      FormData: {},
      tableData: [
      {
          serial_number:"YH001",
          user_name:"张三"
        },
      ],
      //   弹框
      Dialog: {
        editLevy: false,
        DialogData: {},
      },
      loading:false,
      //   分页
      currentPage:1,
      pageSize:20,
      total:400,
      checked:0,
      small:true,
      // 日期选择
      value1:ref(''),
      shortcuts: [
        {
          text: 'Last week',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            return [start, end]
          },
        },
        {
          text: 'Last month',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
          },
        },
        {
          text: 'Last 3 months',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            return [start, end]
          },
        },
      ],

      options: [
  {
    value: '1',
    label: '河南省',
    children: [
      {
        value: '11',
        label: '郑州市',
        children: [
          {
            value: '111',
            label: '金水区',
          },
          {
            value: '112',
            label: '中原区',
          },
          {
            value: '113',
            label: '惠济区',
          },
          {
            value: '114',
            label: '二七区',
          },
        ],
      },
      {
        value: '12',
        label: '洛阳市',
        children: [
          {
            value: '121',
            label: '孟津区',
          },
          {
            value: 'top nav',
            label: 'Top Navigation',
          },
        ],
      },
    ],
  },
  {
    value: '2',
    label: '广东省',
    children: [
      {
        value: '21',
        label: '广州市',
        children: [
          {
            value: 'layout',
            label: 'Layout',
          },
          {
            value: 'color',
            label: 'Color',
          },
          {
            value: 'typography',
            label: 'Typography',
          },
          {
            value: 'icon',
            label: 'Icon',
          },
          {
            value: 'button',
            label: 'Button',
          },
        ],
      },
      {
        value: 'form',
        label: 'Form',
        children: [
          {
            value: 'radio',
            label: 'Radio',
          },
          {
            value: 'checkbox',
            label: 'Checkbox',
          },
          {
            value: 'input',
            label: 'Input',
          },
          {
            value: 'input-number',
            label: 'InputNumber',
          },
          {
            value: 'select',
            label: 'Select',
          },
          {
            value: 'cascader',
            label: 'Cascader',
          },
          {
            value: 'switch',
            label: 'Switch',
          },
          {
            value: 'slider',
            label: 'Slider',
          },
          {
            value: 'time-picker',
            label: 'TimePicker',
          },
          {
            value: 'date-picker',
            label: 'DatePicker',
          },
          {
            value: 'datetime-picker',
            label: 'DateTimePicker',
          },
          {
            value: 'upload',
            label: 'Upload',
          },
          {
            value: 'rate',
            label: 'Rate',
          },
          {
            value: 'form',
            label: 'Form',
          },
        ],
      },
      {
        value: 'data',
        label: 'Data',
        children: [
          {
            value: 'table',
            label: 'Table',
          },
          {
            value: 'tag',
            label: 'Tag',
          },
          {
            value: 'progress',
            label: 'Progress',
          },
          {
            value: 'tree',
            label: 'Tree',
          },
          {
            value: 'pagination',
            label: 'Pagination',
          },
          {
            value: 'badge',
            label: 'Badge',
          },
        ],
      },
      {
        value: 'notice',
        label: 'Notice',
        children: [
          {
            value: 'alert',
            label: 'Alert',
          },
          {
            value: 'loading',
            label: 'Loading',
          },
          {
            value: 'message',
            label: 'Message',
          },
          {
            value: 'message-box',
            label: 'MessageBox',
          },
          {
            value: 'notification',
            label: 'Notification',
          },
        ],
      },
      {
        value: 'navigation',
        label: 'Navigation',
        children: [
          {
            value: 'menu',
            label: 'Menu',
          },
          {
            value: 'tabs',
            label: 'Tabs',
          },
          {
            value: 'breadcrumb',
            label: 'Breadcrumb',
          },
          {
            value: 'dropdown',
            label: 'Dropdown',
          },
          {
            value: 'steps',
            label: 'Steps',
          },
        ],
      },
      {
        value: 'others',
        label: 'Others',
        children: [
          {
            value: 'dialog',
            label: 'Dialog',
          },
          {
            value: 'tooltip',
            label: 'Tooltip',
          },
          {
            value: 'popover',
            label: 'Popover',
          },
          {
            value: 'card',
            label: 'Card',
          },
          {
            value: 'carousel',
            label: 'Carousel',
          },
          {
            value: 'collapse',
            label: 'Collapse',
          },
        ],
      },
    ],
  },
  {
    value: 'resource',
    label: 'Resource',
    children: [
      {
        value: 'axure',
        label: 'Axure Components',
      },
      {
        value: 'sketch',
        label: 'Sketch Templates',
      },
      {
        value: 'docs',
        label: 'Design Documentation',
      },
    ],
  },
]
    });
    const searchBtn = () => {
      getList()
    };
    
    const getList = ()=>{
      // const dataa = data.FormData
      // data.loading = true
      // district_sel(dataa).then((res)=>{
      //   console.log(res);
      //   if (res) {
      //     data.loading = false
      //     if (res.code == 200) {
      //       data.tableData = res.data
      //     } else {
      //       ElMessage.error(res.msg);
      //     }
      //   } else {
      //     ElMessage.error("数据获取失败");
      //   }
      // })
    }






    const handleChange = (value) => {
      console.log(value)
    }
    const handleSizeChange = (val) => {
      data.pageSize = val
    }
    const handleCurrentChange = (val) => {
      data.currentPage = val
      // 
    }

    onMounted(() => {
      console.log(data);
      getList();
    });
    return {
      ...toRefs(data),
      searchBtn,
      getList,
      handleChange,
      handleSizeChange,
      handleCurrentChange
    };
  },
};
</script>
<style scoped>
.formDiv {
  margin-top: 20px;
}
.el-row {
  height: 45px;
}
.el-select /deep/ .el-input__wrapper {
  width: 288px !important;
  --el-input-focus-border-color: #7bd8d3;
}
.btn {
  color: #fff;
  background-color: #03beb0;
  border-color: #03beb0;
  width: 55px;
  height: 28px;
  line-height: 28px;
  margin-left: -100px;
}
.Btns{
  color: #fff;
  background-color: #03beb0;
  border-color: #03beb0;
  width: 175px;
  margin-left: 40px;
  margin-top: 20px; 
}
.btn1 {
  margin-left: 340px;
}
.btn:hover {
  background-color: #05d5c7;
  border-color: #05d5c7;
}

.btn:focus {
  background-color: #03beb0;
  border-color: #03beb0;
}

.BtnSearch{
  float: left;
  width: 70px;
  margin: 0 4px;
  margin-top: 8px;
}

.pagination{
    display: flex;
    justify-content: space-between;
    padding: 0 67px;
    margin-top: -10px;
}
</style>
